body {
	margin: 0;
}
.App {
	text-align: center;
	background-color: #282c34;
	min-height: 100vh;
	margin: auto;
	padding-bottom: 5vh;
	user-select: none;
	font-family: 'Mono';
}
p {
	font-size: 24px;
	color: #CCA;
	margin: 4px;
}
hr {
	border: none;
	border-bottom: 1px solid #484c58;
}

.counter {
	margin: 0px;
	font-size: 72px;
	color: white;
	padding: 20px;
}

.upgradeContainer {
	display: inline-block;
	margin: 4px;
	width: min(450px, 90%);
	vertical-align: top;
}
.interactionButton {
	display: block;
	font-size: 28px;
	margin: 4px auto;
	background-color: transparent;
	border-radius: 8px;
	color: white;
	padding: 8px;
	border: 1px solid #484c58;
	border-bottom: 3px solid #343841;
	cursor: pointer;
	width: min(450px, 100%);
	transition: opacity 0.3s ease-out, background-color 0.3s ease-out, filter 0.3s ease-out;
}
.interactionButton[type="notvisible"] {
	opacity: 0.5;
	background-color: #0001;
	filter: grayscale(1);
	color: transparent;
}
.interactionButton[type="notvisible"] * {
	opacity: 0;
}
.interactionButton[type="notaffordable"] {
	opacity: 0.5;
	background-color: #0004;
	filter: grayscale(0.5);
}
.interactionButton:hover {
	background-color: #FFF1;
}
.interactionButton:active {
	transform: translateY(1px);
	border-bottom: 1px solid #343841;
	margin-block: 5px;
}

.money {
	color: #72d472;
}
.moneyperclick {
	color: #cb5555;
}
.passive {
	color: #d5c454;
}